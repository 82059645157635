<template>
    <section id="iq-favorites">
        <b-container fluid >
            <div class="block-space">
                <b-row>
                    <b-col sm="12" class="overflow-hidden">
                        <div class="iq-main-header d-flex align-items-center justify-content-between">
                        <h4 class="main-title">Project List</h4>
                       <!-- <router-link :to="{ name: 'landing-page.show-single' }" class="text-primary">View all</router-link> -->
                        </div>
                    </b-col>
                </b-row>
                <div class="row">
                    <b-col md="6" class="col-1-5 iq-mb-30" v-for="(item,index) in latestData" :key="index">
                        <div class="epi-box">
                            <div class="epi-img position-relative">
                                <img :src="item.image" class="img-fluid img-zoom" alt="">
                                <div class="episode-number">{{item.episode}}</div>
                                <div class="episode-play-info">
                                    <div class="episode-play">
                                        <router-link :to="`ai-studio`">
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="epi-desc p-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <span class="text-white">{{item.date}}</span>
                                    <span class="text-primary">{{item.time}}</span>
                                </div>
                                <router-link :to="`ai-studio`">
                                    <h6 class="epi-name text-white mb-0">{{item.text}}</h6>
                                </router-link>
                            </div>
                        </div>
                    </b-col>
                </div>
            </div>
        </b-container>
    </section>
</template>
<script>
export default {
  name: 'LatestEpisode',
  components: {
  },
  mounted () {
  },
  data () {
    return {
      latestData: [
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' },
        { image: require('../../../assets/images/aa.png'), episode: '', date: 'ropik one', time: '', text: 'Andong AI-Curator Project' }
      ]
    }
  }
}
</script>
